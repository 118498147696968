<template>
  <div class="promotion-page">
    <header>
      <div class="container">
        <h1>{{ localize('akciya')['title'] }}</h1>
        <promo-timer
          class="countdown"
          width="270px"
        />
      </div>
    </header>

    <section class="container info">
      <div class="row mt-80">
        <div class="image col-md-6 pr-16">
          <img :src="akciyaImage" alt="">
        </div>

        <div class="text col-md-6 pl-16 mt-24">
          <h1>{{ $t('akciyaFrom', { title: 'Uzum Nasiya' }) }}</h1>
          <p>{{ localize('akciya')['text'] }}
            <a class="orange-text" href="http://paymart.uz/doc/aksiya.pdf" target="_blank">{{localize('akciya')['offerta']}}</a>
          </p>
          <!--<ol class="font-weight-700 mt-10" style="line-height: 20px">
            <li
              v-for="(akciya, index) in localize('akciya')['dates']"
              :key="index"
            >
              {{ index + 1 }}. {{ akciya }}
            </li>
          </ol>-->
        </div>
      </div>
    </section>

    <section class="form" id="form">
      <div class="container">
        <div class="row">
          <div class="col-xl-6 col-ml-6 col-lg-6 stock-form">
            <h1>{{ localize('akciya')['status'] }}</h1>
            <p>{{ localize('akciya')['isYouMemberText'] }}</p>

            <input
              v-model="phone"
              v-mask="'+998 (##) ###-##-##'"
              type="tel"
              class="form-control"
              placeholder="+998"
            >

            <main-button
              :is-loading="isLoading"
              @click="check"
            >
              {{ localize('button')['check'] }}
            </main-button>
          </div>

          <div class="col-xl-6 col-ml-6 col-lg-6 form_img">
            <div v-if="userStatus === null" class="text-center">
              <img
                src="../assets/img/order-form-bg.png"
                alt=""
              />
            </div>
            <div v-else>
              <div class="row user-status align-items-center">
                <div class="col-md-6 user-info">
                  <p class="font-weight-700 font-size-24">{{ localize('user') }}</p>
                  <span>{{ $t('phone', { number: resultPhone }) }}</span>

                </div>
                <div class="col-md-6 status-text">
                  <div :class="[
                    'p-12',
                    userStatus === 'member' ? 'status-success': 'status-fail'
                  ]">
                    {{ memberText }}
                  </div>
                </div>
              </div>

              <div class="col-12 pl-0">
                <p :class="[
                    userStatus === 'member' ? 'text-success': 'text-fail',
                    'font-size-18',
                    'mt-24'
                  ]"
                >
                  {{ statusText }}
                </p>

                <main-button
                  v-if="userStatus === 'no-user'"
                  @click="goToAuth"
                  class="w-auto important mt-16"
                >
                  {{ localize('button')['register'] }}
                </main-button>

                <main-button
                  v-if="userStatus === 'overdue'"
                  class="mt-16 w-auto important p-8"
                  @click="goToContracts"
                >
                  {{ localize('button')['repay'] }}
                </main-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import localize from '../filters/localize.filter';
import loadingMixin from '../mixins/loading.mixin';
import MainButton from '../components/buttons/MainButton';
import PromoTimer from '../components/timer/PromoTimer';

import akciyaImageUz from '../assets/img/akciya-uz.png';
import akciyaImageRu from '../assets/img/akciya-ru.png';

export default {
  name: 'StockPage',
  mixins: [loadingMixin],
  data: () => ({
    phone: null,
    resultPhone: null,
    userStatus: null,
  }),
  components: {
    MainButton,
    PromoTimer,
  },
  computed: {
    statusText() {

      let text = null;

      switch (this.userStatus) {
        case 'member':
          text = localize('akciya')['member'];
          break;
        case 'overdue':
          text = localize('akciya')['overdue'];
          break;
        case 'not-verified-user':
          text = localize('akciya')['not-verified-user'];
          break;
        case 'blocked-user':
          text = localize('akciya')['blocked-user'];
          break;
        case 'not-member':
          text = localize('akciya')['not-member'];
          break;
        case 'no-user':
          text = localize('akciya')['no-user'];
          break;
      }

      return text;
    },

    memberText() {
      return this.userStatus === 'member'
        ? localize('akciya')['isMember']
        : localize('akciya')['notMember'];
    },

    akciyaImage() {
      console.log(this.$i18n.locale);
      return this.$i18n.locale === 'ru'
        ? akciyaImageRu
        : akciyaImageUz;
    },
  },
  methods: {
    localize,
    goToContracts() {
      this.$router.push({ name: 'profile.contracts' });
    },
    goToAuth() {
      this.$router.push({ name: 'auth' });
    },
    async check() {
      this.userStatus = null;

      if (this.phone !== null && this.phone.length >= 19) {
        const { data: response } = await this.$axios.post('action/check-status', {
          phone: this.phone,
        }, {
          headers: {
            'Content-Language': this.$i18n.locale,
          },
        });

        if (response.status === 'success') {
          if (response.data.action_detail.action_status === 1) {
            this.userStatus = 'member'; // участник акции
          } else {
            this.userStatus = 'not-member'; // не участник акции
          }

          if (response.data.action_detail.contracts === 0) {
            this.userStatus = 'not-member'; // нет договоров
          }

          if (response.data.action_detail.overdue_days >= 1) {
            this.userStatus = 'overdue'; // просрочник
          }

          if (response.data.buyer_status !== 4) {
            if (response.data.buyer_status === 8) {
              this.userStatus = 'blocked-user'; // заблокирован
            }
            this.userStatus = 'not-verified-user'; // не верифицирован
          }

          this.resultPhone = this.phone;

          this.phone = null;
        } else {
          this.userStatus = 'no-user';
          // response.response.message.forEach(error => this.$toastError(error.text));
          this.resultPhone = this.phone;
          this.phone = null;
        }
      } else {
        this.$toastError(localize('error')['empty']);
        this.phone = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '../assets/css/bussiness.scss';

  @mixin title {
    font-weight: 900;
    font-size: 40px;
    line-height: 40px;
    color: $black;
  }

  .promotion-page {
    padding-bottom: 100px;

    header {
      padding: 56px 0;
      background: $grey url('../assets/icons/Vector.svg') no-repeat 140px 80px;

      @media (max-width: $mobile) {
        background: $grey;
      }

      .container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (max-width: $mobile) {
          display: block;
        }

        h1 {
          @include title;
          @media (max-width: $mobile) {
            text-align: center;
          }
        }

        .countdown {
          box-shadow: unset;
          @media (max-width: $mobile) {
            margin-top: 16px;
          }
        }
      }
    }

    .info {
      .image {
        img {
          -webkit-border-radius: 8px;
          -moz-border-radius: 8px;
          border-radius: 8px;
          width: 100%;
        }
      }

      .text {
        /*margin-top: 36px;*/

        h1 {
          @include title;
          @media (max-width: $mobile) {
            font-size: 36px;
            margin-top: 16px;
          }
        }

        p {
          margin-top: 16px;
          line-height: 24px;
        }
      }
    }

    .orange-text {
      color: #F9A74E;;
    }

    .form {
      margin-top: 100px;

      .stock-form {
        @media (max-width: $mobile) {
          margin-top: 24px;
        }

        h1 {
          font-size: 32px;
          font-weight: 900;
        }

        p {
          margin: 8px 0 36px 0;
        }

        input {
          width: 384px;
          margin: 0 0 24px 0;

          @media (max-width: $mobile) {
            width: 100%;
          }
        }

        button {
          @extend input;
          margin: 0;
        }
      }

      .form_img {
        padding-top: 40px;

        @media (max-width: $mobile) {
          display: block;
          /*justify-content: normal;*/
        }

        .user-status {
          @media (max-width: $mobile) {
            flex-direction: unset;
          }

          .status-text {
            text-align: center;

            @media (max-width: $mobile) {
              margin-top: 10px;
            }

            .status-success {
              background-color: #dff4e8;
              border-radius: 8px;
              color: $success;
            }

            .status-fail {
              background-color: $fail-light;
              border-radius: 8px;
              color: $fail;
            }
          }
        }
      }
    }
  }
</style>
